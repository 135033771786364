<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item>查看售后记录</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rights">
                <div class="box_right_top">采购账号售后记录</div>
                <div class="box_right_cent">
                    <div class="sales-box">
                        <div class="sales-top">
                           <div class="sales-text flex-between">
                               <div class="exchange">
                                  若您购买的商品出现问题，可以在这里申请相关售后服务。
                               </div>
                               <!-- <div>联系客服</div> -->
                               
                            </div>
                           <div class="flex-start sales-query">
                               <div class="flex-start query-box">
                                   <div class="query-text">订单号：</div>
                                   <el-input class="query-input" v-model="ordernumber"></el-input>
                               </div>
                               <div class="flex-start query-box">
                                   <div style="width:80px">采购账号：</div>
                                    <el-select v-model="value" placeholder="请选择" class="query-select">
                                      <el-option
                                      v-for="item in options"
                                      :key="item.id"
                                      :label="item.userAccount"
                                      :value="item.id">
                                      </el-option>
                                    </el-select>
                                   <!-- <div class="query-text">选择更多</div> -->
                               </div>
                               <div class="flex-start query-box">
                                   <div style="width:90px">查询时间：</div>
                                    <el-date-picker
                                        v-model="value1"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间"
                                        :picker-options="pickerBeginDateBefore"
                                    style="width:40%">
                                    </el-date-picker>
                                    <div class="query-zhi">至</div>
                                    <el-date-picker
                                        v-model="value2"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间"
                                        :picker-options="pickerBeginDateBefore"
                                    style="width:40%">
                                    </el-date-picker>
                               </div>
                               <div class="flex-start">
                                   <div class="query-btn" @click="queryBtn()">查询</div>
                                   <!-- <div class="export-btn">导出</div> -->
                               </div>
                           </div>
                        </div>
                        <div class="sales-cent">
                            <el-table
                            :data="tableData"
                            border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                            :cell-style="{ textAlign: 'center' }"
                            style="width: 100%;text-align: center;" @selection-change="handleSelectionChange">
                                <el-table-column
                                type="selection"
                                width="60">
                              </el-table-column>
                                <el-table-column
                                prop="afterSaleNo"
                                label="申请订单单号"
                                width="130">
                                </el-table-column>
                                <el-table-column
                                prop="orderNo"
                                label="订单编号"
                                width="130">
                                </el-table-column>
                                <el-table-column
                                prop="remark"
                                label="商品名称"
                                width="210">
                                </el-table-column>
                                <el-table-column
                                prop="goodsTotalNum"
                                label="数量">
                                </el-table-column>
                                <el-table-column
                                prop="createTime"
                                label="申请时间">
                                </el-table-column>
                                <el-table-column
                                prop="type"
                                label="售后类型">
                                <template slot-scope="scope">
                                    {{typeName(scope.row.type)}}
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="state"
                                label="处理结果">
                                <template slot-scope="scope">
                                    {{stateName(scope.row.state)}}
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="addresss"
                                label="操作">
                                <template slot-scope="scope">
                                  <el-button @click="handleClick(scope.$index,scope.row)" type="text" size="small">查看</el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                            <div class="block" v-show="tableData.length!=0">
                                <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="currentPage"
                                :page-size="20"
                                layout="prev, pager, next, jumper"
                                :total="total">
                                </el-pagination>
                            </div>
                        </div>
                        <div v-show="tableData.length==0">
                          <div style="height:400px;"></div>
                        </div>
                    </div>
                
                </div>
                
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { afterSale,purchase } from "@/api/order/aftersale"
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        currentPage:1,
        tableData: [],
        options: [],
        value: '',
        total:0,
        pageNum:1,
        pageSize:20,
        multipleSelection: [],
        ordernumber:'',
        value1:'',
        value2:'',
        pickerBeginDateBefore:{
          disabledDate(time) {
              return time.getTime() > Date.now();
          }
        },
      }
  },
  computed: {
    typeName() {
        return (type) => {
            switch (type) {
                case 1:
                    return "退款";
                case 2:
                    return "退货退款";
                case 5:
                    return "换货";
                case 6:
                    return "维修";
            }
        };
    },
    stateName(){
      return (state) => {
        switch (state) {
          case 1:
            return "未审核";
          case 2:
            return "已同意";
          case 3:
            return "售后处理中";
          case 4:
            return "售后处理中";
          case 5:
            return "已完成";
          case 6:
            return "已拒绝";
          case 7:
            return "已取消";
          case 8:
            return "售后处理中";
          case 9:
            return "售后处理中";
        }
      };
    }
  },
  created(){
    if (this.$route.params.searchId) {
        this.ordernumber = this.$route.params.searchId
        this.afterSale('','','',this.ordernumber,1,this.pageSize)
    } else {
        this.afterSale('','','','',1,this.pageSize)
    }
    
    purchase().then((data)=>{
      if(data&&data.code=='00000'){
        this.options=data.data
      }
    })
  },
  methods:{
      addTicket(){
        this.$router.push({ name: 'AddTicket' })
      },
      handleSizeChange(val) {
        this.afterSale('','','','',val,this.pageSize)
      },
      handleCurrentChange(val) {
        this.afterSale('','','','',val,this.pageSize)
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      afterSale(userId,beginTime,endTime,orderNo,pageNum,pageSize){
        let data={
          'userId':userId,
          "beginTime": beginTime,
          "endTime": endTime,
          "orderNo": orderNo,
          "pageNum": pageNum,
          "pageSize": pageSize,
        }
        afterSale(data).then(data=>{
          if(data.code=='00000'){
            if(data.data.records.length==0){
              this.tableData=[]
            }else{
              this.tableData=data.data.records
              this.total=data.data.total
            }
          }
        })
      },
      queryBtn(){
        let data = (new Date(this.value1)).getTime()
        let data2 = (new Date(this.value2)).getTime()
        if(data>data2){
            this.$message({
                type: "error",
                message: "开始时间不可以超过结束时间!",
            });
        }else{
          this.afterSale(this.value,this.value1,this.value2,this.ordernumber,1,this.pageSize)
        }
      },
      //查看售后信息
      handleClick(index,row){
        console.log(index,row)
        this.$router.push({ name: "ReturnGoods",  query: { id: row.id},});
      },
  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rights{
    width: 87%;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .sales-box{
        .sales-top{
            padding: 20px;
            font-size: 15px;
            .sales-text{
                margin-bottom: 20px;
            }
            .sales-exchange{
                margin-bottom: 20px;
                .exchange>span{
                    color: #CD9F49;
                    border-bottom: 1px solid #CD9F49;
                    padding-bottom: 3px;
                    margin-left: 15px;
                }
            }
            .sales-query{
                .query-box{
                    margin-right: 10px;
                }
                .query-text{
                    width: 70px;
                }
                .query-select{
                    width: 100px;
                }
                .query-input{
                   width: 100px;
                }
                .query-zhi{
                    margin: 0 10px;
                }
                .query-btn{
                    width: 55px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    background: #CD9F49;
                    border-radius: 4px;
                }
                .export-btn{
                    width: 55px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    font-size: 14px;
                    margin: 0 15px;
                    border: 1px solid #999;
                    color: #999;
                    border-radius: 4px;
                }
            }
        }
        
    }
    .sales-cent{
        margin-bottom: 30px;
        .block{
            text-align: center;
            margin: 15px 0;
        }
        .el-button--text{
          color: #CD9F49;
        }
    }
   
  }
}
</style>